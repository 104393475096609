import * as moment from "../../../../public/assets/vendor/moment/moment";
export function sleep(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
export function getPostbackParameters(params) {
    var postbackParameters = {};
    params.forEach(function (value, key) {
        var transformedKey = key;
        var parts = key.split('postback_parameters');
        if (parts.length > 1) {
            transformedKey = parts[1].replace(/(\[|\])/g, "");
            postbackParameters[transformedKey] = value;
        }
    });
    return postbackParameters;
}
export function getAge(birthday, format) {
    var formattedBirthday = moment(birthday, format || 'YYYY-MM-DD');
    var now = moment();
    var monthDiff = Number(now.month() - formattedBirthday.month());
    var dayDiff = Number(now.format('DD')) - Number(formattedBirthday.format('DD'));
    var age = Number(now.year() - formattedBirthday.year());
    if (monthDiff < 0) {
        return age - 1;
    }
    if (monthDiff === 0 && dayDiff < 0) {
        return age - 1;
    }
    return age;
}
export function eventGTM(event) {
    var dataLayer = window.dataLayer || [];
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function () {
        dataLayer.push(event);
    });
}
