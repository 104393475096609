var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getPostbackParameters } from "./utils";
import { Tracking } from "./tracking";
var ImageTracking = (function (_super) {
    __extends(ImageTracking, _super);
    function ImageTracking(configuration) {
        var _this = _super.call(this, configuration) || this;
        _this.ocapiHost = 'https://publisher.api.optincollect.com';
        _this.host = 'https://api.optinproject.com';
        return _this;
    }
    ImageTracking.prototype.generate = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var affiliateCampaignId, tracking, params, s2s, identifier, postbackParametersQueryString, url;
            return __generator(this, function (_c) {
                affiliateCampaignId = this.configuration.affiliateCampaignId;
                tracking = this.getTrackingFromSession();
                if (!tracking) {
                    throw new Error("Missing tracking key");
                }
                params = new window.URLSearchParams(tracking.urlParameters);
                s2s = params.get('var_oc');
                if (!s2s) {
                    throw new Error("Missing s2s");
                }
                identifier = (_b = (_a = this.configuration) === null || _a === void 0 ? void 0 : _a.hash) !== null && _b !== void 0 ? _b : 'auto';
                postbackParametersQueryString = Object.entries(getPostbackParameters(params)).reduce(function (postback, item) {
                    var index = item[0];
                    var value = item[1];
                    var postbackString = 'postback_parameters[' + index + ']=' + value + '';
                    return "" === postback ? postbackString : postback + '&' + postbackString;
                }, "");
                url = 'auto' === affiliateCampaignId
                    ? this.ocapiHost + "/s2s/lead.json?uid=" + identifier + "&s2s=" + s2s
                    : this.host + "/s2s/lead/" + affiliateCampaignId + ".json?uid=" + identifier + "&s2s=" + s2s;
                if (this.configuration.paybackMode) {
                    url += "&mode=" + this.configuration.paybackMode.toUpperCase();
                }
                if ("" !== postbackParametersQueryString) {
                    url += '&' + postbackParametersQueryString;
                }
                this.generateImg(url);
                return [2];
            });
        });
    };
    ImageTracking.prototype.generateWithCallableAndOptinSpl = function (callable, splIdExpected) {
        return __awaiter(this, void 0, void 0, function () {
            var tracking, params;
            return __generator(this, function (_a) {
                tracking = this.getTrackingFromSession();
                if (!tracking) {
                    console.log("Missing external tracking");
                    return [2];
                }
                params = new window.URLSearchParams(tracking.urlParameters);
                if (Number(params.get('optinsplid')) !== splIdExpected) {
                    return [2];
                }
                callable();
                return [2];
            });
        });
    };
    ImageTracking.prototype.generateImg = function (src, appendToSelector) {
        if (appendToSelector === void 0) { appendToSelector = 'body'; }
        var imgTracking = window.document.createElement('img');
        imgTracking.width = "1";
        imgTracking.height = "1";
        imgTracking.border = "0";
        imgTracking.src = src;
        var appendTo = window.document.querySelector(appendToSelector);
        return appendTo ? appendTo.append(imgTracking) : window.document.body.append(imgTracking);
    };
    return ImageTracking;
}(Tracking));
window['OptinTracking'] = ImageTracking;
window.dispatchEvent(new window.CustomEvent("external_tracking_loaded"));
